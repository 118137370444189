import React from 'react';
import { Badge, Box, Grid, Text, Tooltip } from '@chakra-ui/react';
import { displayCurrency, orUndefined } from 'common/utils';
import { LineItemWithProduct } from '../types';

const renderCell = (
  label: string | number,
  isNumeric: boolean = false,
  isTruncated: boolean = false,
) => {
  const text = (
    <Text
      fontSize="sm"
      textAlign={orUndefined('right', isNumeric)}
      textOverflow={orUndefined('ellipsis', isTruncated)}
      overflow={orUndefined('hidden', isTruncated)}
      whiteSpace={orUndefined('nowrap', isTruncated)}
    >
      {label}
    </Text>
  );
  return (
    <Box overflow={isTruncated ? 'hidden' : undefined}>
      {isTruncated ? (
        <Tooltip label={label} placement="auto">
          {text}
        </Tooltip>
      ) : text}
    </Box>
  );
};

export const QuoteLineItem = (props: LineItemWithProduct) => {
  return (
    <Box borderBottomWidth="1px" p="1" pt="2" pb="2" _last={{ borderBottomWidth: 0 }}>
      <Grid templateColumns="1fr 3fr .5fr 1fr 1fr" columnGap="2" alignItems="center">
        <Box>
          <Badge>{props.productId}</Badge>
        </Box>
        {renderCell(props.product.displayName, false, true)}
        {renderCell(props.quantity, true, false)}
        {renderCell(displayCurrency(props.product.price), true, false)}
        {renderCell(displayCurrency(props.total), true, false)}
      </Grid>
    </Box>
  );
};
