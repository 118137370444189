import { atom, selector, selectorFamily } from 'recoil';
import { ById } from 'common/types';
import { productsMockData } from './mockData';
import { Product } from './types';

export const productsState = atom<ById<Product>>({
  key: 'ProductsState',
  default: productsMockData,
});

export const productByIdState = selectorFamily<Product, string>({
  key: 'ProductByIdState',
  get: (productId) => ({ get }) => {
    const products = get(productsState);
    return products[productId];
  },
});

export const productsListState = selector<Product[]>({
  key: 'ProductsListState',
  get: ({ get }) => {
    const products = get(productsState);
    return Object.entries(products).map(([_, product]) => product);
  },
});
