import React from 'react';
import {
  Box,
  Divider,
  Flex,
  Heading,
  Link,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/layout';
import { useQuote } from '../hooks';
import { displayCurrency } from 'common/utils';

export const QuoteTotals = () => {
  const quote = useQuote();

  return (
    <Box borderRadius="4px" width="60%" maxW="300px" bgColor="gray.50" p="4" alignSelf="flex-end">
      <VStack alignItems="flex-end">
        <Flex width="100%" alignItems="center">
          <Text alignSelf="flex-start" fontSize="sm">Subtotal</Text>
          <Spacer />
          <Heading color="gray.500" fontWeight="medium" size="sm">
            {displayCurrency(quote.subtotal)}
          </Heading>
        </Flex>
        <Flex width="100%" alignItems="center">
          <Text alignSelf="flex-start" fontSize="sm">Taxes</Text>
          <Spacer />
          <Heading color="gray.500" fontWeight="medium" size="sm">
            {displayCurrency(quote.taxes)}
          </Heading>
        </Flex>
        <Divider />
        <Flex width="100%" alignItems="center">
          <Text alignSelf="flex-start" fontSize="md" fontWeight="bold">
            Estimated Total*
          </Text>
          <Spacer />
          <Heading size="sm">
            {displayCurrency(quote.total)}
          </Heading>
        </Flex>
      </VStack>
      <Text fontSize="xx-small">
        Quoted prices are valid for 30 days
      </Text>
      <Text fontSize="xx-small">
        For more information, view our <Link color="blue" href="/">terms and conditions</Link>
      </Text>
    </Box>
  );
};
