import React from 'react';
import { FaCheck } from 'react-icons/fa'
import {
  Button,
  Textarea,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { quoteLineItemsState } from '../atoms';

export const RequestQuoteModal = () => {
  const hasLineItems = useRecoilValue(quoteLineItemsState).length > 0;
  return (
    <Popover placement="left-start">
      <PopoverTrigger>
        <Button colorScheme="green" size="sm" disabled={!hasLineItems}>
          Request Quote
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Contact Information</PopoverHeader>
        <PopoverBody>
          <VStack>
            <FormControl>
              <FormLabel fontSize="sm">
                Name
              </FormLabel>
              <Input size="sm" />
            </FormControl>
            <FormControl>
              <FormLabel fontSize="sm">
                Company
              </FormLabel>
              <Input size="sm" />
            </FormControl>
            <FormControl>
              <FormLabel fontSize="sm">
                Additional Information
              </FormLabel>
              <Textarea size="sm" />
            </FormControl>
            <Button size="sm" w="100%" rightIcon={<FaCheck />}>Send</Button>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
