import * as React from 'react'
import { RecoilRoot } from 'recoil'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { CatalogAndQuote } from 'pages/CatalogAndQuote/CatalogAndQuote';

const theme = extendTheme({
  components: {
    Td: {
      sizes: {
        xs: {
          padding: 0,
        }
      }
    }
  }
});

export const App = () => (
  <RecoilRoot>
    <ChakraProvider theme={theme}>
      <CatalogAndQuote />
    </ChakraProvider>
  </RecoilRoot>
)
