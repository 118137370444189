import React from 'react';
import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/table';
import { ProductCategoryGrouping } from '../../types';
import { Badge, Box, Divider, Heading, HStack } from '@chakra-ui/layout';
import { displayCurrency } from 'common/utils';
import { IconButton } from '@chakra-ui/button';
import { useSetLineItems } from 'features/quote/hooks';
import { FaPlus } from 'react-icons/fa';

type Props = {
  groups: ProductCategoryGrouping[];
};

export const CondensedCatalog = ({ groups }: Props) => {
  const { updateLineItemById } = useSetLineItems();
  const handleAddProduct = React.useCallback((id: string) => () => {
    updateLineItemById(id, 1);
  }, [updateLineItemById]);
  return (
    <Box overflowY="auto" position="relative" flexGrow={2} mb="10">
      {groups.map(group => (
        <Box key={`condensed-catalog-product-group-${group.displayName}`}>
          <HStack p="2" pl="0" pr="0">
            <Heading color="red" size="xs" fontWeight="bold" whiteSpace="nowrap">{group.displayName}</Heading>
            <Divider />
          </HStack>
          <Table size="sm">
            <Thead>
              <Tr bgColor="gray.100">
                <Th p="1" textTransform="capitalize" fontWeight="bold" color="blackAlpha.900">ID</Th>
                <Th p="1" textTransform="capitalize" fontWeight="bold" color="blackAlpha.900">Name</Th>
                <Th p="1" textTransform="capitalize" fontWeight="bold" color="blackAlpha.900" isNumeric>Price</Th>
                <Th p="1" textTransform="capitalize" fontWeight="bold" color="blackAlpha.900" isNumeric></Th>
              </Tr>
            </Thead>
            <Tbody>
              {group.products.map((product) => (
                <Tr key={`condensed-catalog-product-group-${group.displayName}-product-${product.id}`}>
                  <Td p="1">
                    <Badge fontSize="xs">{product.id}</Badge>
                  </Td>
                  <Td p="1">{product.displayName}</Td>
                  <Td p="1" isNumeric>{displayCurrency(product.price)}</Td>
                  <Td p="1" isNumeric minW="0">
                    <IconButton  onClick={handleAddProduct(product.id)} size="xs" aria-label="add" icon={<FaPlus />} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      ))}
    </Box>
  );
};
