import { Box, Grid } from '@chakra-ui/layout'
import React from 'react';
import { Catalog } from 'features/product/components/Catalog';
import { QuoteComponent } from 'features/quote/components/Quote';

export const CatalogAndQuote = () => {
  return  (
    <Grid wrap="nowrap" templateColumns="60% 40%" overflow="hidden">
      <Box height="100vh" p="6">
        <Catalog />
      </Box>
      <Box height="100vh" d="flex" boxShadow="lg" p="4">
        <QuoteComponent />
      </Box>
    </Grid>
  );
}
