import React from 'react';
import { Box, Flex, Spacer, Text, IconButton } from '@chakra-ui/react'
import { FaThLarge, FaList } from 'react-icons/fa';
import { useProducts } from '../hooks';
import { Filters } from './Filters';
import { useCatalogUiState } from 'features/catalog/hooks';
import { orUndefined } from 'common/utils';
import { ProductsSection } from './ProductsSection';
import { CondensedCatalog } from './condensed/CondensedCatalog';

export const Catalog = () => {
  const products = useProducts();
  const [catalogUiState, setCatalogUiState] = useCatalogUiState();

  return (
    <Box d="flex" flexDir="column" h="100vh" position="relative" pb="">
      <Box>
        <Filters />
        <Flex alignItems="center" mb="2">
          <Text color="gray.400" fontSize="sm" mb="2">Found 4 results</Text>
          <Spacer />
          <IconButton
            aria-label="Grid view"
            icon={<FaThLarge />}
            size="xs"
            colorScheme={orUndefined('blue', !catalogUiState.isCondensedView)}
            onClick={() => setCatalogUiState(prev => ({ ...prev, isCondensedView: false }))}
          />
          <IconButton
            ml="2"
            aria-label="Grid list"
            icon={<FaList />}
            size="xs"
            colorScheme={orUndefined('blue', catalogUiState.isCondensedView)}
            onClick={() => setCatalogUiState(prev => ({ ...prev, isCondensedView: true }))}
          />
        </Flex>
      </Box>
      {catalogUiState.isCondensedView ? (
        <CondensedCatalog
          groups={[
            { displayName: 'Pressure Treated Lumber', products },
            { displayName: 'Untreated Lumber', products },
            { displayName: 'Fasteners', products },
            { displayName: 'Test', products },
          ]}
        />
      ) : (
        <ProductsSection products={products} displayName="Pressure Treated Lumber" />
      )}
    </Box>
  );
};
