import React from 'react';
import { FaPlus } from 'react-icons/fa'
import {
  Badge,
  Box,
  Divider,
  Flex,
  Heading,
  Spacer,
  Text,
  VStack,
  IconButton,
} from '@chakra-ui/react';
import { displayCurrency } from 'common/utils';
import { Product } from '../types';
import { useSetLineItems } from '../../quote/hooks';

export const ProductCard = ({ id, displayName, price }: Product) => {
  const { updateLineItemById } = useSetLineItems();
  const handleAddProduct = React.useCallback(() => {
    updateLineItemById(id, 1);
  }, [updateLineItemById, id]);

  return (
    <Box borderWidth="1px" borderRadius="4px" p="4">
      <VStack alignItems="flex-start" spacing="1">
        <Flex alignItems="center" width="100%">
          <Text fontSize="sm" fontWeight="bold">
            {displayCurrency(price)}
          </Text>
          <Divider orientation="vertical" height="12px" ml="2" mr="2" />
          <Badge fontSize="xx-small">{id}</Badge>
          <Spacer />
          <IconButton onClick={handleAddProduct} size="xs" aria-label="add" icon={<FaPlus />} />
        </Flex>
        <Heading fontSize="md" fontWeight="medium">{displayName}</Heading>
      </VStack>
    </Box>
  );
}
