import React from 'react';
import { Box, Grid, Heading } from '@chakra-ui/layout';
import { Product } from '../types';
import { ProductCard } from './ProductCard';

type Props = {
  displayName: string;
  products: Product[];
};

export const ProductsSection = ({ displayName, products }: Props) => {
  return (
    <Box mb="4" _last={{ mb: '0' }}>
      <Heading size="md" mb="2">{displayName}</Heading>
      <Grid gridTemplateColumns="repeat(3, 1fr)" columnGap="2" rowGap="2">
        {products.map(product => (
          <ProductCard {...product} key={`product-card-${product.id}`} />
        ))}
      </Grid>
    </Box>
  );
};
