import React from 'react';
import { Box, Flex, Spacer, Heading, Center, Text } from '@chakra-ui/layout';
import { useQuote } from '../hooks';
import { QuoteLineItem } from './QuoteLineItem';
import { QuoteLineItemColumns } from './QuoteLineItemColumns';
import { QuoteTotals } from './QuoteTotals';
import { RequestQuoteModal } from './RequestQuoteModal';
import { Avatar } from '@chakra-ui/avatar';

export const QuoteComponent = () => {
  const quote = useQuote();
  return (
    <Box width="100%" h="100%" d="flex" flexDir="column">
      <Flex w="100%" alignItems="center" mb="2">
        <Avatar
          size="md"
          src="https://p.kindpng.com/picc/s/28-280000_84-lumber-company-hd-png-download.png"
          name="84 Lumber"
        />
        <Heading ml="2" size="md">84 Lumber Company</Heading>
        <Spacer />
        <RequestQuoteModal />
      </Flex>
      <Flex direction="column" h="100%" mt="2">
        <Box w="100%">
          <QuoteLineItemColumns />
          {quote.lineItems.map((lineItem) => (
            <QuoteLineItem key={`quote-line-item-${lineItem.productId}`} {...lineItem} />
          ))}
          {quote.lineItems.length <= 0 && (
            <Center w="100%" mt="2" mb="2">
              <Text color="gray.500" fontSize="xs">You haven't added any items</Text>
            </Center>
          )}
        </Box>
        <Spacer />
        <QuoteTotals />
      </Flex>
    </Box>
  );
};
