import { atom, selector } from 'recoil';
import { productsState } from '../product/atoms';
import { LineItem, Quote } from './types';

export const quoteLineItemsState = atom<LineItem[]>({
  key: 'QuoteLineItemsState',
  default: [],
});

export const quoteState = selector<Quote>({
  key: 'QuoteState',
  get: ({ get }) => {
    const products = get(productsState);
    const lineItems = get(quoteLineItemsState).map(lineItem => {
      const product = products[lineItem.productId];
      return {
        ...lineItem,
        product,
        total: product.price * lineItem.quantity,
      };
    });

    const subtotal = lineItems.reduce((prev, curr) => prev + curr.total, 0);
    return {
      lineItems,
      subtotal,
      taxes: 0,
      total: subtotal,
    };
  },
});

