import { ById } from 'common/types';
import { Product } from './types';

export const productsMockData: ById<Product> = {
  'PTL-18824': {
    id: 'PTL-18824',
    displayName: '2x4x10 Pressure Treated Spruce',
    price: 13.67,
    unit: 'ea',
  },
  'PTL-99214': {
    id: 'PTL-99214',
    displayName: '2x4x12 Pressure Treated Spruce',
    price: 14.67,
    unit: 'ea',
  },
  'PTL-89139': {
    id: 'PTL-89139',
    displayName: '2x4x16 Pressure Treated Spruce',
    price: 14.67,
    unit: 'ea',
  },
  'PTL-11531': {
    id: 'PTL-11531',
    displayName: '2x4x8 Pressure Treated Spruce',
    price: 14.67,
    unit: 'ea',
  },
};
