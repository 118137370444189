import React from 'react';
import {
  Button,
  Popover,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Box,
  Divider,
  Flex,
  Spacer,
  Wrap,
  Radio,
  RadioGroup,
  Tag,
  Input,
} from '@chakra-ui/react';
import { FaAngleDown } from 'react-icons/fa'

const mockCategories = [
  'Lumber',
  'Pressure Treated Lumber',
  'Untreated Lumber',
  'Fasteners',
  'Screws',
  'Hardware',
];

export const Filters = () => {
  return (
    <Box mb="4">
      <Flex alignItems="center">
        <Input placeholder="Search..." maxWidth="250px" size="sm" borderRadius="4px" />
        <Spacer />
        <Button size="sm" variant="link">Clear Filters</Button>
        <Divider h="4" mr="2" ml="2" orientation="vertical" />
        <Popover>
          <PopoverTrigger>
            <Button size="sm" rightIcon={<FaAngleDown />}>Availability</Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverHeader>Filter by availability(in stock)</PopoverHeader>
            <RadioGroup>
              <Wrap p="4">
                {['10', '25', '50', '100', '200', '500', '1000+'].map(availability => (
                  <Radio key={`availability-${availability}`}>{availability}</Radio>
                ))}
              </Wrap>
            </RadioGroup>
          </PopoverContent>
        </Popover>
        <Divider h="4" mr="2" ml="2" orientation="vertical" />
        <Popover>
          <PopoverTrigger>
            <Button size="sm" rightIcon={<FaAngleDown />}>Categories</Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverHeader>Select Categories</PopoverHeader>
            <Wrap p="4">
              {mockCategories.map(category => (
                <Tag key={`category-${category}`}>{category}</Tag>
              ))}
            </Wrap>
          </PopoverContent>
        </Popover>
      </Flex>
    </Box>
  );
};
