import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { quoteLineItemsState, quoteState } from './atoms';

export const useSetLineItems = () => {
  const setLineItems = useSetRecoilState(quoteLineItemsState);

  const removeLineItem = React.useCallback((productId: string) => setLineItems(prev => {
    const oldLineItems = [...prev];
    const index = oldLineItems.findIndex(el => el.productId === productId);

    if (index === -1) return prev;

    oldLineItems.splice(index, 1);
    return oldLineItems;
  }), [setLineItems]);

  const updateLineItemById = React.useCallback((productId: string, quantity: number) => setLineItems(prev => {
    const oldLineItems = [...prev];
    const index = oldLineItems.findIndex(el => el.productId === productId);

    if (index === -1) return [...prev, { productId, quantity }];

    oldLineItems[index] = { ...prev[index], quantity: prev[index].quantity + quantity };
    return oldLineItems;
  }), [setLineItems]);

  const updateLineItemByIndex = React.useCallback((index: number, quantity: number) => setLineItems(prev => {
    const oldLineItems = [...prev];
    oldLineItems[index].quantity = quantity;
    return oldLineItems;
  }), [setLineItems]);

  return {
    removeLineItem,
    updateLineItemById,
    updateLineItemByIndex,
  };
};

export const useQuote = () => useRecoilValue(quoteState);
