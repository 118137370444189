import React from 'react';
import { Box, Grid, Text } from '@chakra-ui/layout';
import { orUndefined } from 'common/utils';

const COLUMNS = [
  { name: 'ID'},
  { name: 'Name'},
  { name: 'Qty', isNumeric: true },
  { name: 'Price', isNumeric: true },
  { name: 'Total', isNumeric: true }
];

export const QuoteLineItemColumns = () => {
  const renderColumn = React.useCallback((label: string, isNumeric?: boolean) => (
    <Box>
      <Text
        fontWeight="medium"
        fontSize="xs"
        textAlign={orUndefined('right', isNumeric)}
      >
        {label}
      </Text>
    </Box>
  ), []);
  return (
    <Box borderBottomWidth="1px" p="1" pt="2" pb="2" width="100%">
      <Grid templateColumns="1fr 3fr .5fr 1fr 1fr" columnGap="2" alignItems="center">
        {COLUMNS.map((col) => renderColumn(col.name, col.isNumeric))}
      </Grid>
    </Box>
  );
};
